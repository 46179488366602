<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1000px">
      <v-card light>
        <v-card-title>
          <span>Nacherfassung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  class="pb-5"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  ref="status"
                  v-model="statusFilter"
                  :items="statusOptions"
                  prepend-icon="mdi-calendar-question"
                  label="Status"
                  clearable
                ></v-select>
              </v-col>
            </v-row>

            <v-data-table
              ref="mainTable"
              :loading="isDisabled"
              :headers="headers"
              :items="items"
              :server-items-length="totalEntries"
              :options.sync="dataOptions"
              :footer-props="{
                itemsPerPageText: 'Einträge pro Seite',
                itemsPerPageAllText: 'Alle',
              }"
              loading-text="Daten werden geladen... Bitte warten"
              :single-expand="true"
              show-expand
              :expanded.sync="expanded"
              @item-expanded="expandedChanged"
              @pagination="paginationChanged"
              class="elevation-1"
            >
              <template #footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} von
                {{ props.itemsLength }}
              </template>

              <template slot="no-data">
                <div>Keine Daten zur Nacherfassung gefunden</div>
              </template>

              <template v-slot:item.geburtsdatum="{ item }">
                {{ item.geburtsdatum | formatDate }}
              </template>

              <template v-slot:item.appointmentCounter="{ item }">
                <span
                  :style="[
                    item.termine_completed != item.termine_total
                      ? { color: 'red' }
                      : { color: 'black' },
                  ]"
                  >{{ item.termine_completed }}/{{ item.termine_total }}</span
                >
              </template>

              <template v-slot:item.invalidCounter="{ item }">
                <span v-if="item.termine_invalid > 0" style="color: red ">{{ item.termine_invalid }}</span>
                <span v-else>-</span>
              </template>


              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dense
                      @click="openAddPatient(item)"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="item.patient_selected || isDisabled"
                    >
                      mdi-account-plus
                    </v-icon>
                  </template>
                  <span>Patient erfassen</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dense
                      @click="openAddRezept(item)"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="
                        !item.patient_selected ||
                          item.rezept_selected ||
                          isDisabled
                      "
                    >
                      mdi-text-box-plus
                    </v-icon>
                  </template>
                  <span>Rezept erfassen</span>
                </v-tooltip>
              </template>

              <template v-slot:expanded-item="{ item: parentItem }">
                <td :colspan="headers.length">
                  <div style="position: relative;">
                    <v-overlay :value="inlineLoading" :absolute="true">
                      <v-progress-circular
                        indeterminate
                        size="64"
                      ></v-progress-circular>
                    </v-overlay>

                    <v-alert
                      v-if="hasSelectedAppointments(parentItem)"
                      color="#C51162"
                      dark
                      dense
                      class="mt-2"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          Ausgewählte Termine:
                          {{ inlineTableData[parentItem.id].length }}
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                            @click="openSplitterDialog(parentItem.id)"
                            :disabled="parentItem.termine.length < 2"
                            small
                            outlined
                          >
                            <v-icon left>
                              mdi-call-split
                            </v-icon>
                            Aufteilen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>

                    <v-data-table
                      v-model="inlineTableData[parentItem.id]"
                      :key="subTableKey"
                      :headers="inlineHeaders"
                      :items="parentItem.termine"
                      class="ma-4"
                      dense
                      hide-default-footer
                      :show-select="parentItem.termine.length > 1"
                      :items-per-page="-1"
                      :height="
                        parentItem.termine.length > 8
                          ? 280
                          : (parentItem.termine.length + 1) * 32
                      "
                      :loading="isDisabled"
                    >
                      <template v-slot:item.startDate="{ item }">
                        <span class="weekday">{{
                          item.startDate | formatDateWeekday
                        }}</span>
                        <span>{{ item.startDate | formatDate }}</span>
                      </template>

                      <template v-slot:item.mitarbeiter="{ item }">
                        <span>{{ getMitarbeiterName(item.mitarbeiter) }}</span>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              @click="moveTermin(parentItem, item)"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="
                                isDisabled || item.status == 'Erledigt'
                              "
                            >
                              mdi-update
                            </v-icon>
                          </template>
                          <span>Verschieben</span>
                        </v-tooltip>

                        <v-tooltip
                          bottom
                          v-if="!['Abgesagt', 'Ungültig'].includes(item.status)"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="confirmTermin(item.id)"
                              :disabled="
                                isFutureAppointment(
                                  item.endDate,
                                  item.endTime
                                ) ||
                                  isDisabled ||
                                  item.status == 'Erledigt'
                              "
                            >
                              mdi-check-bold
                            </v-icon>
                          </template>
                          <span>Bestätigen</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.status != 'Abgesagt'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="rejectTermin(item.id)"
                              :disabled="
                                isDisabled || item.status == 'Erledigt'
                              "
                            >
                              mdi-close-thick
                            </v-icon>
                          </template>
                          <span>Absagen</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.status == 'Abgesagt'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="openDeleteDialog(item.id)"
                              :disabled="isDisabled"
                            >
                              mdi-trash-can
                            </v-icon>
                          </template>
                          <span>Löschen</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </div>
                </td>
              </template>

              <template v-slot:item.patient_status="{ item }">
                <v-icon v-if="item.patient_selected" small color="green">
                  mdi-check-circle
                </v-icon>

                <v-icon v-if="!item.patient_selected" small color="red">
                  mdi-alert-circle
                </v-icon>
              </template>

              <template v-slot:item.rezept_status="{ item }">
                <v-icon v-if="item.rezept_selected" small color="green">
                  mdi-check-circle
                </v-icon>

                <v-icon v-if="!item.rezept_selected" small color="red">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="closeWindow"
            >Zurück</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="splitterDialogActive" width="510">
      <v-card>
        <v-card-title>Terminaufteilung</v-card-title>
        <v-card-text>
          <div class="content">
            <div class="mb-2">
              <span style="font-weight: bold; color: red"
                >Sie sind dabei die aktuelle Termingruppe aufzuteilen!</span
              >
            </div>

            <div class="mb-2">
              <span v-if="selectedAppointments.length > 1">
                Die ausgewählten
                <span style="font-weight: bold;">{{
                  this.selectedAppointments.length
                }}</span>
                Termine verbleiben
              </span>
              <span v-if="selectedAppointments.length == 1">
                Der ausgewählte Termin verbleibt
              </span>
              <span>
                in der aktuellen Gruppe und alle anderen Termine werden in eine
                neue Gruppe <u>(ohne Rezept)</u>
                verschoben.
              </span>
            </div>

            <span>
              Bitte bestätigen Sie die Aufteilung.
            </span>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeSplitterDialog"
            :disabled="isDisabled"
            >Zurück</v-btn
          >
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="splitSelectedAppointments(selectedGroupId)"
            :loading="isDisabled"
            :disabled="isDisabled"
            >Aufteilen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="450px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin löschen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDeleteDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Möchten Sie diesen Termin wirklich löschen?</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDialog">
            Abbrechen
          </v-btn>
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="handleDeleteAppointment"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";

const initialData = () => ({
  componentName: "Nacherfassung",
  headers: [
    {
      text: "Name",
      value: "name",
      sortable: true,
      align: "start",
    },
    {
      text: "Vorname",
      value: "vorname",
      sortable: true,
    },
    {
      text: "Geburtsdatum",
      value: "geburtsdatum",
      sortable: true,
    },
    {
      text: "KT-Typ",
      value: "patient.kt_typ",
      sortable: true,
      align: "start",
    },

    { text: "", value: "data-table-expand", sortable: false },
    {
      text: "Patient",
      value: "patient_status",
      sortable: false,
      align: "center",
    },
    {
      text: "Rezept",
      value: "rezept_status",
      sortable: false,
      align: "center",
    },

    {
      text: "Termine",
      value: "appointmentCounter",
      sortable: false,
      align: "center",
    },

    {
      text: "Ungültig",
      value: "invalidCounter",
      sortable: false,
      align: "center",
    },

    {
      text: "Aktionen",
      value: "actions",
      sortable: false,
      align: "center",
    },
  ],

  inlineHeaders: [
    {
      text: "Datum",
      value: "startDate",
      sortable: false,
      align: "start",
    },
    {
      text: "Uhrzeit",
      value: "startTime",
      sortable: false,
      align: "start",
    },
    {
      text: "Behandlung",
      value: "name",
      sortable: false,
      align: "start",
    },
    {
      text: "Mitarbeiter",
      value: "mitarbeiter",
      sortable: false,
      align: "start",
    },
    {
      text: "Status",
      value: "status",
      sortable: false,
      align: "start",
    },
    {
      text: "Aktionen",
      value: "actions",
      sortable: false,
      align: "center",
    },
  ],

  inlineTableData: {},

  search: "",
  isDisabled: false,

  expanded: [],

  splitterDialogActive: false,
  selectedGroupId: null,
  selectedAppointments: [],

  snackbar: false,
  moved: false,

  inlineLoading: false,

  dataOptions: {
    sortBy: ["name"],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 5,
    multiSort: true,
  },

  statusOptions: ["Offen", "Erledigt", "Abgesagt", "Ungültig"],
  statusFilter: null,

  items: [],
  totalEntries: 0,

  initLoad: false,
  deleteDialog: false,
  appointmentIdToDelete: null,

  subTableKey: 1,
});

export default {
  name: "Nacherfassung",
  props: ["switchToTermin"],

  data() {
    return initialData();
  },

  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(String(value)).format("DD.MM.YYYY");
    },

    formatDateWeekday(value) {
      if (!value) return "";
      moment.locale("de");
      return moment(String(value)).format("ddd");
    },
  },

  computed: {
    active() {
      return this.$store.getters["overlays/nacherfassungActive"];
    },

    patientActive() {
      return this.$store.getters["overlays/patientAddActive"];
    },

    rezeptActive() {
      return this.$store.getters["overlays/rezeptActive"];
    },

    rejectActive() {
      return this.$store.getters["overlays/terminRejectActive"];
    },

    passedStatus() {
      return this.$store.getters["overlays/nacherfassungStatus"];
    },

  },

  methods: {
    ...mapActions("overlays", [
      "closeAndClearNacherfassung",
      "setTerminRejectId",
    ]),
    ...mapMutations("overlays", [
      "openAddRezept",
      "openAddPatient",
      "openTerminReject",
      "openTerminSplitter",
      "openTerminMove",
    ]),
    ...mapActions("termine", [
      "updateTerminStatus",
      "splitAppointments",
      "deleteAppointment",
    ]),
    ...mapActions("nacherfassung", ["queryNacherfassung"]),

    moveTermin(parentItem, item) {
      this.moved = true;

      item.name =
        parentItem.name + ", " + parentItem.vorname + " - " + item.name;
      item.termintyp = "Behandlung";

      let data = {
        componentName: this.componentName,
        termin: item,
      };

      this.switchToTermin(item);
      this.openTerminMove(data);
    },

    getMitarbeiterName(id) {
      return this.$store.getters["mitarbeiter/getMitarbeiterNamebyId"](id);
    },

    getOrder() {
      // iterate over sortBy and sortDesc e.g.
      // sortBy: ["start", "end"],
      // sortDesc: [false, true]
      // and return "start,-end"

      let replacementMap = {
        name: "surname",
        vorname: "name",
        geburtsdatum: "birthdate",
        "patient.kt_typ": "kt_typ",
      };

      let order = [];
      for (let i = 0; i < this.dataOptions.sortBy.length; i++) {
        let desc = this.dataOptions.sortDesc[i] ? "-" : "";
        let name = this.dataOptions.sortBy[i];

        if (replacementMap[name]) {
          name = replacementMap[name];
        }

        order.push(desc + name);
      }
      return order.join(",");
    },

    clearSelections() {
      Vue.set(this, "inlineTableData", {});
      // Also close any expanded items
      this.expanded = [];
    },

    expandedChanged({ value }) {
      if (!value) {
        this.clearSelections();
      }
    },

    paginationChanged() {
      this.clearSelections();
    },

    openSplitterDialog(groupId) {
      this.splitterDialogActive = true;
      this.selectedGroupId = groupId;
      this.selectedAppointments = this.inlineTableData[groupId];
    },

    closeSplitterDialog() {
      this.splitterDialogActive = false;
      this.selectedGroupId = null;
      this.clearSelections();
    },

    hasSelectedAppointments(item) {
      const id = item.id;
      if (!this.inlineTableData[id]) return false;

      return (
        this.inlineTableData[id].length > 0 &&
        this.inlineTableData[id].length != item.termine.length
      );
    },

    openDeleteDialog(id) {
      this.appointmentIdToDelete = id;
      this.isDisabled = true;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.appointmentIdToDelete = null;
      this.isDisabled = false;
      this.deleteDialog = false;
    },

    closeWindow() {
      // reset data
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.closeAndClearNacherfassung();
    },

    isFutureAppointment(endDate, endTime) {
      let now = moment();
      let appointment = moment(endDate + " " + endTime, "YYYY-MM-DD HH:mm");
      return appointment.isAfter(now);
    },

    async splitSelectedAppointments(groupId) {
      const data = {
        groupId: groupId,
        appointmentIds: this.inlineTableData[groupId].map(
          (appointment) => appointment.id
        ),
      };

      this.isDisabled = true;
      await this.splitAppointments(data);
      await this.getData();
      this.closeSplitterDialog();
    },

    async rejectTermin(terminId) {
      this.setTerminRejectId(terminId);
      this.openTerminReject();
    },

    async confirmTermin(terminId) {
      this.isDisabled = true;

      let data = {
        id: terminId,
        status: "Erledigt",
      };

      // update termin status
      await this.updateTerminStatus(data);
      await this.getData();

      // reset selected
      this.inlineTableData = {};
    },

    async getData() {
      let params = {
        page: this.dataOptions.page,
        pageSize: this.dataOptions.itemsPerPage,
      };

      if (this.dataOptions.sortBy) {
        params["orderBy"] = this.getOrder();
      }

      if (this.search) {
        params["search"] = this.search;
      }

      if (this.statusFilter) {
        params["status"] = this.statusFilter;
      }

      this.isDisabled = true;

      let data = {};
      try {
        data = await this.queryNacherfassung(params);
      } catch (e) {
        if (e.response.status == 400) {
          this.dataOptions.page = 1;
          data = await this.queryNacherfassung(params);
        }
      }

      this.items = data.results;
      this.totalEntries = data.totalEntries;
      this.isDisabled = false;
    },

    async handleDeleteAppointment() {
      this.deleteDialog = false;
      await this.deleteAppointment(this.appointmentIdToDelete);
      await this.getData();
      this.closeDeleteDialog();
    },
  },
  watch: {
    async patientActive(val) {
      if (!val && this.active) {
        // update nacherfassung data
        await this.getData();
      }
    },

    async rejectActive(val) {
      if (!val && this.active) {
        // update nacherfassung data
        await this.getData();
      }
    },

    async rezeptActive(val) {
      if (!val && this.active) {
        // update nacherfassung data
        await this.getData();
      }
    },

    isDisabled() {
      // The subTableKey is used to force a re-render of the sub-table
      // when the data is updated. This is necessary because there is a bug in Vue 2 where slot data
      // is rendered in the wrong order when updating data and using v-if (e.g. it looks like already rendered data is not placed correctly).
      // https://github.com/vuejs/core/issues/11336
      this.subTableKey += 1;
    },

    async active(val) {

      if (val && this.passedStatus){
        this.statusFilter = this.passedStatus;
      }

      if (val && (this.moved || this.items.length == 0)) {
        this.moved = false;

        // update nacherfassung data
        this.inlineLoading = true;
        await this.getData();
        this.inlineLoading = false;
        this.initLoad = true;
      }
    },

    async search() {
      if (this.active) {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(async () => {
          this.dataOptions.page = 1;
          await this.getData();
        }, 500);
      }
    },

    async statusFilter() {
      if (this.active) {
        this.dataOptions.page = 1;
        await this.getData();
      }
    },

    async dataOptions() {
      if (this.active && this.initLoad) {
        await this.getData();
      }
    },
  },

  mounted() {},
};
</script>

<style>
.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  transition: inherit;
  width: 22px;
  left: -6px;
  top: calc(50% - 18px);
  margin: 7px;
}

.weekday {
  width: 32px;
  display: inline-grid;
}
</style>
